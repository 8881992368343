<template>
  <div>
    <h1>Blank Page</h1>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "blank",
  data() {
    return {
      message: "Hellow World"
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Blank" }]);
    ApiService.get("/diseases")
      .then(({ data }) => {
        console.log(data);
        this.message = data;
      })
      .catch(({ error }) => {
        console.log(error);
      });
  }
};
</script>
